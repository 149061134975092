import type {
  MedicalCaseSource,
  MedicalCase,
  MedicalCaseListElementSource,
  MedicalCaseListElement,
} from './models'
import { formatToMenuListElement } from '~/typeEntities/stores/Menu'
import { formatDoctorMinData } from '~/typeEntities/stores/Doctor'
import { formatArticleListElement } from '~/typeEntities/stores/Article'

export const formatMedicalCase = (
  medicalCaseSource: MedicalCaseSource
): MedicalCase => {
  return {
    id: medicalCaseSource.id,
    title: medicalCaseSource.title,
    detailDescription: medicalCaseSource.detailDescription,
    riskDescription: medicalCaseSource.riskDescription,
    durationDescription: medicalCaseSource.durationDescription,
    costDescription: medicalCaseSource.costDescription,
    editedAt: medicalCaseSource.editedAt,
    quality: medicalCaseSource.quality,
    totalPrice: medicalCaseSource.totalPrice,
    totalPriceNote: medicalCaseSource.totalPriceNote,
    photoOrderDirection: medicalCaseSource.photoOrderDirection,
    patientGenderStr: patientGenderStr(medicalCaseSource.patientGender),
    patientAgeStr: patientAgeStr(medicalCaseSource.patientAge),
    status: medicalCaseSource.status,
    note: medicalCaseSource.note,
    bodyPart: medicalCaseSource.bodyPart,
    doctorNote: medicalCaseSource.doctorNote,
    storeId: medicalCaseSource.storeId,
    menus: medicalCaseSource.medicalCaseMenus.map((medicalCaseMenu) => {
      return formatToMenuListElement(medicalCaseMenu.menu)
    }),
    medicalCasePhotos: medicalCaseSource.medicalCasePhotos,
    doctor: medicalCaseSource.doctor
      ? formatDoctorMinData(medicalCaseSource.doctor)
      : null,
    articles: medicalCaseSource.medicalCaseArticles.map(
      (medicalCaseArticle) => {
        return formatArticleListElement(medicalCaseArticle.article)
      }
    ),
  }
}

export const formatMedicalCaseListElement = (
  medicalCaseListElementSource: MedicalCaseListElementSource
): MedicalCaseListElement => {
  return {
    id: medicalCaseListElementSource.id,
    title: medicalCaseListElementSource.title,
    photoOrderDirection: medicalCaseListElementSource.photoOrderDirection,
    editedAt: medicalCaseListElementSource.editedAt,
    status: medicalCaseListElementSource.status,
    quality: medicalCaseListElementSource.quality,
    totalPrice: medicalCaseListElementSource.totalPrice,
    patientGenderStr: patientGenderStr(
      medicalCaseListElementSource.patientGender
    ),
    patientAgeStr: patientAgeStr(medicalCaseListElementSource.patientAge),
    storeId: medicalCaseListElementSource.storeId,
    treatments: medicalCaseListElementSource.medicalCaseMenus.flatMap(
      (medicalCaseMenu) => {
        return medicalCaseMenu.menu.menuTreatments.flatMap((menuTreatment) => {
          return menuTreatment.treatment
        })
      }
    ),
    medicalCasePhotos: medicalCaseListElementSource.medicalCasePhotos,
    doctor: medicalCaseListElementSource.doctor || null,
  }
}

const patientGenderStr = (patientGender: string) => {
  switch (patientGender) {
    case 'female':
      return '女性'
    case 'male':
      return '男性'
    case 'unknown':
      return 'ー'
    default:
      return ''
  }
}

const patientAgeStr = (patientAge: string) => {
  switch (patientAge) {
    case 'early20':
      return '20代前半'
    case 'late20':
      return '20代後半'
    case 'early30':
      return '30代前半'
    case 'late30':
      return '30代後半'
    case 'early40':
      return '40代前半'
    case 'late40':
      return '40代後半'
    case 'early50':
      return '50代前半'
    case 'late50':
      return '50代後半'
    case 'early60':
      return '60代前半'
    case 'late60':
      return '60代後半'
    case 'unknown':
      return 'ー'
    default:
      return ''
  }
}
