import type { BrandWithClinicList, BrandWithClinicListSource } from './models'
import { formatClinicSnippet } from '~/typeEntities/stores/Clinic'

export const formatBrandWithClinicList = (
  brand: BrandWithClinicListSource
): BrandWithClinicList => {
  return {
    id: brand.id,
    name: brand.name,
    stores: brand.stores.map(formatClinicSnippet),
  }
}
